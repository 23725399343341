import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

import NavigationBar from "../components/NavigationBar";
import Footer from "../components/Footer";
import SEO from "../components/seo";
import "./ListPage.css";

import BackArrow from "./back-arrow.svg";

const ADDRESS = process.env.GATSBY_API_URL;

const ListPage = ({ data, pageContext }) => {
  const { list } = pageContext;
  const { id, name, listItems } = list;
  const [showMap, setShowMap] = useState(false);
  const [locations, setLocations] = useState([]);
  const [yourName, setYourName] = useState("");
  const [yourEmail, setYourEmail] = useState("");
  const [yourRecommendation, setYourRecommendation] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const observer = useRef();
  const mapRef = useRef();

  useEffect(() => {
    const locationItems = listItems
      .filter((listItem) => listItem.item.location)
      .map((listItem) => listItem.item.location);
    setLocations(locationItems);
    setShowMap(locationItems.length > 0);
  }, [listItems]);

  const fitBounds = useCallback(() => {
    if (mapRef.current && locations.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      locations.forEach((location) => {
        bounds.extend(location.position);
      });
      mapRef.current.fitBounds(bounds);
    }
  }, [locations]);

  useEffect(() => {
    fitBounds();
  }, [showMap, locations, fitBounds]);

  // Helper function to strip "The " prefix for sorting
  const stripPrefix = (str) => {
    return str.toLowerCase().startsWith("the ") ? str.slice(4) : str;
  };

  // Create observer to lazy load images
  const createObserver = useCallback(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    observer.current = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const img = entry.target.querySelector("img");
          if (img) {
            img.src = img.dataset.src;
            img.onload = () => img.removeAttribute("data-src");
          }
          observer.unobserve(entry.target);
        }
      });
    }, options);
  }, []);

  useEffect(() => {
    createObserver();
    const elements = document.querySelectorAll(".list-item");
    elements.forEach((element) => observer.current.observe(element));
  }, [createObserver, listItems]);

  const containerStyle = {
    width: "100%",
    height: "450px",
  };

  const handleRecommendationChange = (e) => {
    setYourRecommendation(e.target.value);
  };

  const handleRecommendationSubmit = async (e) => {
    e.preventDefault();
    if (yourRecommendation.trim() === "") return;

    try {
      const response = await fetch(`${ADDRESS}/backend/recommendation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: yourName,
          email: yourEmail,
          recommendation: yourRecommendation,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Optionally, handle the response data if needed
      const data = await response.json();
      console.log("Recommendation submitted:", data);

      // Clear the input fields and close the dialog
      setYourName("");
      setYourEmail("");
      setYourRecommendation("");
      setIsDialogOpen(false);
    } catch (error) {
      console.error(
        "There was a problem with the recommendation submission:",
        error
      );
    }
  };

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <main>
      <NavigationBar />
      <div className="hero-section">
        <div className="back-heading">
          <Link to="/">
            <img className="back-arrow" src={BackArrow} />
          </Link>
          <h1>{name}</h1>
        </div>
      </div>
      <div className="lists-section">
        <div className="container">
          <div className="list-header">
            <div className="export-buttons">
              {/* <button onClick={exportToGoogleSheet}>
                Export to Google Sheet
              </button>
              <button onClick={exportToNotesApp}>Export to Notes</button> */}
            </div>
          </div>
          <div className="list-items">
            {listItems
              .sort((a, b) =>
                stripPrefix(a.item.name).localeCompare(stripPrefix(b.item.name))
              )
              .map((listItem, index) => {
                const itemCompletionsWithImages = listItem.item.itemCompletions
                  .filter((itemCompletion) => itemCompletion.image)
                  .sort((a, b) => new Date(b.date) - new Date(a.date));
                return (
                  <div key={index} className="list-item">
                    <h2>{listItem.title}</h2>
                    <div className="item-detail">
                      <div className="thumbnail">
                        {itemCompletionsWithImages &&
                          itemCompletionsWithImages.length > 0 && (
                            <img
                              data-src={itemCompletionsWithImages[0].image}
                              alt={listItem.item.name}
                              src="" // Placeholder src for lazy loading
                            />
                          )}
                        {(!itemCompletionsWithImages ||
                          itemCompletionsWithImages.length === 0) && (
                          <div className="incomplete-placeholder">
                            <div>Incomplete</div>
                          </div>
                        )}
                      </div>
                      <div className="details">
                        <p>{listItem.item.name}</p>
                        {listItem.item.location && (
                          <p className="item-location">
                            {listItem.item.location.name}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="recommendation">
            <div className="recommendation-text">
              Have I missed something? Leave a recommendation!
            </div>
            <div className="recommendation-button">
              <button onClick={openDialog}>Submit Recommendation</button>
            </div>
          </div>
          {showMap && (
            <div className="map-container">
              <LoadScript googleMapsApiKey="AIzaSyCnmvG_lRlFzVHbXatgeM9kASgJLXd3-7w">
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  onLoad={(map) => {
                    mapRef.current = map;
                    fitBounds();
                  }}
                >
                  {locations.map((location, index) => (
                    <Marker
                      key={index}
                      position={location.position}
                      title={location.name}
                    />
                  ))}
                </GoogleMap>
              </LoadScript>
            </div>
          )}
        </div>
      </div>
      {isDialogOpen && (
        <div className="dialog-overlay">
          <div className="dialog">
            <h2>Submit Your Recommendation</h2>
            <form onSubmit={handleRecommendationSubmit}>
              <input
                type="text"
                value={yourName}
                onChange={(e) => setYourName(e.target.value)}
                placeholder="Your name"
              />
              <input
                type="text"
                value={yourEmail}
                onChange={(e) => setYourEmail(e.target.value)}
                placeholder="Your email"
              />
              <textarea
                type="text"
                value={yourRecommendation}
                onChange={handleRecommendationChange}
                placeholder="Your recommendation"
              />
              <button type="submit">Submit</button>
              <button type="button" onClick={closeDialog}>
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
      <Footer />
    </main>
  );
};

export default ListPage;

export const Head = ({ pageContext }) => {
  const { list } = pageContext;
  const { id, name, listItems } = list;
  const location = useLocation();
  return (
    <SEO index={true} follow={true} title={name} pathname={location.pathname} />
  );
};
