import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const SEO = (page) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `);

  const defaults = data.site.siteMetadata;

  if (defaults.siteUrl === "" && typeof window !== "undefined") {
    defaults.siteUrl = window.location.origin;
  }

  if (defaults.siteUrl === "") {
    console.error("Please set a siteUrl in your site metadata!");
    return null;
  }

  const title = page.name || defaults.title;
  const description = page.description || defaults.description;

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={page.url} />
      <meta name="description" content={description} />
      {/* {image && <meta name="image" content={image} />} */}

      {/* <meta property="og:url" content={url} /> */}
      <meta property="og:type" content="article" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {/* {image && <meta property="og:image" content={image} />} */}

      <meta name="twitter:card" content="summary_large_image" />
      {/* <meta name="twitter:creator" content={page.author.twitter} /> */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {/* {image && <meta name="twitter:image" content={image} />} */}
    </Helmet>
  );
};

export default SEO;
